import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import api_rest from 'src/app/api_rest';
import { CERTIFICADO_INACAL } from 'src/app/models/certificado-inacal';

@Injectable({
  providedIn: 'root'
})
export class CertificadoInacalApiService {

  API_URI = api_rest.server.API_URI;

  constructor(private http: HttpClient) { }
  mostrarTodos(){

    return this.http.get(`${this.API_URI}mostrarTodos.php`);

  }
  agregar(usuario){
    return this.http.post(`${this.API_URI}agregar.php`, JSON.stringify(usuario));
  }
  eliminar(id_usuario:number){
    return this.http.get(`${this.API_URI}eliminar.php?id_usuario=${id_usuario}`);
  }
  seleccionar(id_usuario:number){
    return this.http.get(`${this.API_URI}seleccionar.php?id_usuario=${id_usuario}`);
  }
  update(usuario){
    return this.http.post(`${this.API_URI}update.php`, JSON.stringify(usuario));
  }
  getPromisePosterior(nro_certificado: number, codigo_hash: string, dni_codigo: string, fecha:string):Promise<CERTIFICADO_INACAL[]> {
    console.log("Consulta API", `${this.API_URI}seleccionar.php?nro_certificado=${nro_certificado}&codigo_hash=${codigo_hash}&dni_codigo=${dni_codigo}&fecha=${fecha}`);
    return this.http.get<CERTIFICADO_INACAL[]>(`${this.API_URI}seleccionar.php?codigo_hash=${codigo_hash}&dni_codigo=${dni_codigo}`).toPromise(); 
  }
  getPromiseInicial(codigo_hash: string, dni_codigo: string):Promise<CERTIFICADO_INACAL[]> {
    console.log("Consulta API", `${this.API_URI}list-verificacion-inicial.php?codigo_hash=${codigo_hash}&dni_codigo=${dni_codigo}`);
    return this.http.get<CERTIFICADO_INACAL[]>(`${this.API_URI}list-verificacion-inicial.php?codigo_hash=${codigo_hash}&dni_codigo=${dni_codigo}`).toPromise(); 
  }

  getAllPromise():Promise<CERTIFICADO_INACAL[]> {
    return this.http.get<CERTIFICADO_INACAL[]>(`${this.API_URI}mostrarTodos.php`).toPromise();
  }

}
