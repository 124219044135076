import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CertificadoInacalApiService } from 'src/app/services/api/certificado-inacal-api.service';

@Component({
  selector: 'app-certificados',
  templateUrl: './certificados.component.html',
  styleUrls: ['./certificados.component.scss']
})
export class CertificadosComponent implements OnInit {

  @Input() url: string;
  certificadoForm: FormGroup;
  urlPDF = null;
  codigoHash = null;
  view: boolean = true;


  constructor(
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private router: Router,
    private certificadoInacalApi: CertificadoInacalApiService,
    private route: ActivatedRoute,
    private _snackBar:MatSnackBar) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.codigoHash = params.get('codigoHash');
      console.log("Codigo Hash", this.codigoHash);
      this.buildForm();
    });
  }
  
  private buildForm(){
    this.certificadoForm = this.formBuilder.group({
      CODIGO_SEGURIDAD: ["", Validators.required]
    });
  }

  async onSubmit() {
    if (this.certificadoForm.valid) {
      
      if(this.codigoHash==null){
        this.openSnackBar("No se encontró el codigo hash, vuelve a escanear el código QR del certificado", "");
      }else{
        // Envías los datos incluyendo la respuesta del captcha
        const list:any = await this.certificadoInacalApi.getPromiseInicial(this.codigoHash, this.certificadoForm.value.CODIGO_SEGURIDAD);
        //console.log(fechaFormateada);
        console.log(list);
        if(list.length>0){
          this.urlPDF = await this.sanitizarURL(list[0].URL);
          this.view=false;
        }else{        
          this.openSnackBar(list.mensaje, "");
          this.view=true;
        }
      }
    } else{
      this.openSnackBar("Para continuar debe ingresar el código seguridad.", "");
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      panelClass: ["success-snackbar"],
      duration: 10000
    });
  }

  async sanitizarURL(url: string): Promise<SafeResourceUrl> {
    await this.simulateAsyncOperation();
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  simulateAsyncOperation(): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000); // Simula un retraso de 1 segundo
    });
  }
  exit(){
    this.router.navigate(['/certificados'], { replaceUrl: true });
  }

}
