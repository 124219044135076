import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CertificadoInacalApiService } from './services/api/certificado-inacal-api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'consulta-certificado-inacal';
  certificadoForm: FormGroup;
  captchaValid: boolean = false;

  constructor(private formBuilder: FormBuilder, private certificadoInacalApi: CertificadoInacalApiService,
    private router:Router) {}

  ngOnInit() {
    this.buildForm()
  }

  private buildForm(){
    this.certificadoForm = this.formBuilder.group({
      NRO_CERTIFICADO: ['', Validators.required],
      FECHA_EJECUCION: [(new Date()).toISOString(), Validators.required],
      DNI_CODIGO: ['', Validators.required],
      RECAPTCHA: ['', Validators.required]
    });
  }

  onCaptchaResolved(captchaResponse: string) {
    if (captchaResponse) {
      this.certificadoForm.patchValue({ RECAPTCHA: captchaResponse });
      this.captchaValid = true;
    } else {
      this.captchaValid = false;
    }
  }

  async onSubmit() {
    if (this.certificadoForm.valid && this.captchaValid) {
      // Envías los datos incluyendo la respuesta del captcha
      
      const fecha = new Date(this.certificadoForm.value.FECHA_EJECUCION);
      const fechaFormateada = fecha.toISOString().split('T')[0];
      const list = await this.certificadoInacalApi.getPromisePosterior(this.certificadoForm.value.NRO_CERTIFICADO, null, this.certificadoForm.value.DNI_CODIGO, fechaFormateada);
      console.log(fechaFormateada);
      console.log(list);
      this.router.navigate(["/pdf-certificado-inacal"]);
    } else if(this.captchaValid) {
      alert('Por favor, complete los datos del formulario');
    }else{
      alert('Por favor, complete el captcha');
    }
  }

}