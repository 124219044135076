
<div class="container" *ngIf="view">
    <mat-card class="card">
      <mat-card-title><img mat-card-image src="https://www.tdemperu.com/wp/wp-content/uploads/2017/04/LOGO-TDEM-original.jpg" alt="Photo of a Shiba Inu"></mat-card-title>
      <div class="line"></div>
      <mat-card-subtitle>	Consulta de Certificado</mat-card-subtitle>
      <mat-card-content>
        <form [formGroup]="certificadoForm" novalidate class="example-form" (ngSubmit)="onSubmit()">
          <mat-form-field appearance="outline"  class="full-width">
            <mat-label>Código Seguridad <span class="span-required">*</span></mat-label>
            <input matInput type="text" placeholder="Código Seguridad" formControlName="CODIGO_SEGURIDAD" >
          </mat-form-field>
          
          <button mat-raised-button type="submit"  class="full-width button-color">CONSULTAR</button>
        </form>
      </mat-card-content>
    </mat-card>
</div>

<div *ngIf="!view">
    <mat-toolbar class="toolbar-color">
        <img mat-card-image src="https://www.tdemperu.com/wp/wp-content/uploads/2017/04/LOGO-TDEM-original.jpg" alt="Photo of a Shiba Inu">
        <span class="title">Consulta de certificados de inspección</span>
        <span class="example-spacer"></span>
        <button mat-icon-button class="example-icon class-button" aria-label="Example icon-button with share icon" (click)="exit()">
            <mat-icon>power_settings_new</mat-icon>Salir
        </button>
    </mat-toolbar>
    <div class="iframe-container">
        <iframe [src]="urlPDF" width="800" height="1160"></iframe>
    </div>
</div>
